import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { DataService } from 'src/app/services/data.service';
import { AppService } from "src/app/services/app.service";
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { Router, ActivatedRoute, Params, NavigationEnd } from "@angular/router";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  AbstractControl,
  FormArray,
  NgForm,

} from "@angular/forms";
import { first } from 'rxjs/operators';
import { CustomValidators } from "ng2-validation";
import { HttpClient, HttpErrorResponse, HttpEvent, HttpRequest } from '@angular/common/http';
import { style } from '@angular/animations';
import { element } from 'protractor';
import { Observable } from 'rxjs/internal/Observable';
import { KysCorporateFeasComponent } from '../kys-corporate-feas.component';

@Component({
  selector: 'app-corp-basic-info',
  templateUrl: './corp-basic-info.component.html',
  styleUrls: ['./corp-basic-info.component.scss']
})
export class CorpBasicInfoComponent implements OnInit {
  @Output() output = new EventEmitter<any>();

  accountID: any;
  companyData: any;
  copmanyAccountInfoData: any;
  companyForm: FormGroup;
  submitData: any;
  isBankCheck: boolean = false;
  basicDone: any;
  currentStatus:any[] = [
    {key: 'Active', value: 'Active'},
    {key: 'Dormant', value: 'Dormant'},
    {key: 'Active in Progress', value: 'Active in Progress'},
    {key: 'Under liquidation', value: 'Under liquidation'},
    {key: 'Under process of Striking Off', value: 'Under process of Striking Off'},
    {key: 'Strike off', value: 'Strike off'},
    {key: 'Amalgamated', value: 'Amalgamated'},
    {key: 'Liquidated', value: 'Liquidated'},
    {key: 'Dissolved', value: 'Dissolved'},
    {key: 'Converted to LLP', value: 'Converted to LLP'},
    {key: 'Converted to LLP and dissolved', value: 'Converted to LLP and dissolved'}
  ]
  countries: any;
  jurisdictions: any;
  
  constructor(
    private formBuilder: FormBuilder,
    private _data: DataService,
    private _app: AppService,
    public router: Router,
    public route: ActivatedRoute,
    private http: HttpClient) { }

  ngOnInit() {
    this.getAllCountries();
    this.getAccountInfo();
    this.companyForm = this.formBuilder.group({
      nameOfEntity: ['', [
        Validators.required,
        Validators.pattern(/^[a-zA-Z ]+$/)
      ]],
      Jurisdiction: ['', [
        Validators.required,
      ]],
      cuurentStatus: [''],
      companyType: ['',[Validators.pattern(/^[a-zA-Z ]+$/)]],
      branchCount: ['',[Validators.pattern(/^[0-9]\d*(\.\d+)?$/)]],
      pCompany: ['',[Validators.pattern(/^[a-zA-Z0-9 ]+$/)]],
      cAddress: [''],
      region: [''],
      companyID: ['', [
        Validators.required,
        Validators.pattern(/^[a-zA-Z0-9 ]+$/)
      ]],
      country: ['', [
        Validators.required,
      ]],
      incorporateDate: [''],
      lei: [''],
      registryUrl: [''],
      pCompanyID: ['',[Validators.pattern(/^[0-9]\d*(\.\d+)?$/)]],
      locality: [''],
      postalCode: ['',[Validators.pattern(/^[0-9]\d*(\.\d+)?$/)]]
    });
    this._data.corporateBasicInfo.subscribe(res => {
      this.basicDone = res;
    })
  }

  getAllCountries() {
    this._app
      .getAllCountries()

      .subscribe(
        (res) => {
          this.countries = res['data'];
        },
        (err) => {
        }
      );
  }

  getCountryCode(countryCode, ngOnInit?){
    let country;
    if(countryCode){
      country = countryCode
    }else{
      country = this.companyForm.value.country;
    }
    this._app.getJurisdictionData(country).subscribe((res) => {
      this.jurisdictions = res || [];
      if(ngOnInit){
        this.patchForm();
      }else{
        if(res.length){
          this.companyForm.get('Jurisdiction').setValue(res[0].code);
        }
      }
    })
  }

  get companyControls(): { [key: string]: AbstractControl } {
    return this.companyForm.controls;
  }

  getAccountInfo() {
    this._app.getAccountInfoforCorp().pipe(first()).subscribe((data) => {
      this.copmanyAccountInfoData = data["data"]
      this.accountID = this.copmanyAccountInfoData.accountId
      this._app.getAllCorpChecks(this.accountID).subscribe(res => {
        if (res['data'] && res['data'].bankAccountCheck) {
          this.isBankCheck = true;
          this._data.isBankCheckVal(this.isBankCheck)
          this.companyForm.addControl('accountNumber', this.formBuilder.control('', Validators.required));
          this.companyForm.addControl('ifscCode', this.formBuilder.control('', Validators.required));
          this.companyForm.addControl('accountName', this.formBuilder.control('', Validators.required));
        }
        this.getCompanyInfo(this.accountID)
      });
    })

  }

  onDateInputKeydown(event: KeyboardEvent) {
    event.preventDefault(); // Prevent any key input
  }

  patchForm(){
    this.companyForm.patchValue({
      nameOfEntity: this.companyData.companyName,
      accountNumber: this.companyData.accountNumber,
      ifscCode: this.companyData.ifscCode,
      accountName: this.companyData.name,
      Jurisdiction: this.companyData.jurisdictionCode,
      cuurentStatus: this.companyData.currentStatus,
      companyType: this.companyData.companyType,
      branchCount: this.companyData.branchCount,
      pCompany: this.companyData.parentCompanyName,
      cAddress: this.companyData.streetAddress,
      region: this.companyData.region,
      companyID: this.companyData.companyID,
      country: this.companyData.country && this.companyData.country.countryCode ? this.companyData.country.countryCode : "",
      incorporateDate: this.companyData.incorporationDate,
      lei: this.companyData.lei,
      //registryUrl: this.companyData.,
      pCompanyID: this.companyData.parentCompanyID,
      locality: this.companyData.locality,
      postalCode: this.companyData.postalCode,
    });
    if(this.companyData.country && this.companyData.country.countryCode){
      this._data.isCountryUploadedVal(this.companyData.country.countryCode);
    }else{
      this._data.isCountryUploadedVal(false);
    }
  }

  getCompanyInfo(id) {
    this._app.getCompanyInfo(id).pipe(first()).subscribe((data) => {
      this.companyData = data["data"]
      this._data.nameVal(this.companyData)
      localStorage.setItem("companyKysNo", this.companyData.tpe.kysNo)
      // console.log("hgfhjsg", this.companyData.country.countryCode);
      if(this.companyData.country && this.companyData.country.countryCode){
        this.getCountryCode(this.companyData.country.countryCode, true)
        localStorage.setItem('countryCode', this.companyData.country.countryCode);
      }else{
        this.patchForm();
      }
    })
  }

  postBasicInfo() {
    let abc = {
      "registrationName": null,
      "lei": this.companyForm.value.lei,
      "companyID": this.companyForm.value.companyID,
      "incorporationDate": this.companyForm.value.incorporateDate,
      "currentStatus": this.companyForm.value.cuurentStatus,
      "companyType": this.companyForm.value.companyType,
      "branchCount": parseInt(this.companyForm.value.branchCount),
      "parentCompanyName": this.companyForm.value.pCompany,
      "parentCompanyID": this.companyForm.value.pCompanyID,
      "streetAddress": this.companyForm.value.cAddress,
      "locality": this.companyForm.value.locality,
      "region": this.companyForm.value.region,
      "postalCode": this.companyForm.value.postalCode,
      "accountNumber": this.companyForm.value.accountNumber,
      "ifscCode": this.companyForm.value.ifscCode,
      "name": this.companyForm.value.accountName,
      "registeredAddressInFull": null,
      "kysCountryCode":this.companyForm.value.country,
      "jurisdictionCode":this.companyForm.value.Jurisdiction
    }
    this.submitBasicInfo(abc, this.accountID)
  }

  submitBasicInfo(obj, id) {
    this._data.changeLoaderVisibility(true);
    this._app.submitBasicInfoforCorp(obj, id).pipe(first()).subscribe((data) => {
      this.submitData = data["data"]
      this._data.changeLoaderVisibility(false)
      this._data.toastrShow('Your Details have been saved successfully', `success`);
      this.getCompanyInfo(this.accountID);
      if(this.basicDone){
        this.basicDone = { ...this.basicDone, kysTpeCheckStatus: 'COMPLETED' }
      }
      let status = this.basicDone
      this._app.sendStatus(status).subscribe(() => {
        this._app.getStatus().subscribe(res => {
          console.log(res, "status")
          this._data.checksStatus(res['data']);
          this.output.emit('basicDone');
        })
      })
      const payload = {
        "message": 'Company Details have been saved successfully',
        "page": "Company Basic Info Update",
        "geoLocation": null
      }
      this._app.feasAddActivityLog(localStorage.getItem("companyKysNo"), payload).subscribe();
    },
      (err: HttpErrorResponse) => {
        const payload = {
          "message": err,
          "page": "Company Basic Info Update",
          "geoLocation": null
        }
        this._app.feasAddActivityLog(localStorage.getItem("companyKysNo"), payload).subscribe();
      }
    )
  }

}
